.header-top-menu {
    padding: 16px 0 !important;

    a:hover {
        color: $secondary;
        i {
            color: $secondary;
        }
    }

    li {
        margin: 0 !important;
    }
}

header {
    .header-menu {
        li.active > a {
            color: $secondary !important;
        }

        li:has(.sub-menu li.active) > a {
            color: $secondary !important;
        }

        ul li:last-of-type a { margin-right: 0 !important; }
    }
}

//|------------------------------------
//| Responsive header
//|------------------------------------
@media (min-width: calc($breakpoint-lg + 1px)) {
    .m-logo {
        display: none !important;
    }
}

@media (max-width: $breakpoint-lg) {
    .header-area {
        padding: 10px;
        background: #fff;

        &.sticky-nav {
            animation: none !important;
        }
    }
    .menu-wrapper {
        background: #fff;
        width: 100%;
        position: absolute; z-index: 100;

        &:not(.active-nav) {
            .header-menu {
                display: none;
            }
        }

        li {
            a {
                color: #333 !important;
            }

            i {
                color: #333 !important;
                transition: all .2s;
            }

            &.active {
                i {
                    rotate: 90deg;
                }
            }
        }

        li:not(.active):hover .sub-menu {
            opacity: 0 !important;
            visibility: hidden !important;
        }


        .sub-menu {
            // [[reset styling]]
            inset: 0;
            position: initial !important;
            top: initial;
            background: transparent !important;
            padding: 0 !important;
            box-shadow: none !important;
            // [[end reset styling]]
            height: 0 !important;
            width: 100% !important;

            ul {
                padding: 0 25px !important;

                li {
                    border: none !important;

                    a::before {
                        content: none !important;
                        display: none !important;
                        visibility: hidden !important;
                    }
                }
            }
        }

        li.active .sub-menu {
            height: auto !important;
            opacity: 1 !important;
            visibility: visible !important;

            li {
                a {
                    color: #fff !important;
                }
            }
        }
    }
}