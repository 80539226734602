section.sector-subhero {
    ul { margin-left: 1rem !important; }

    .start_paragraph {
        p {
            font-weight: 500;
            color: #13287d;
        }
    }
    .sidebar-cleaning-box {
        ul {
            list-style: none;
            
            li {
                list-style: none;
                position: relative;

                i {
                    top: calc(50% - 4px); transform: translateY(-50%);
                }
            }
        }
    }
}