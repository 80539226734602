section.services {
    .service-item {
        padding: 20px 20px 45px;
        border-radius: 5px;
        @media (max-width: $breakpoint-sm) {
            background: transparent;
            padding-bottom: 0 !important
        }
        margin-bottom: 30px;
        .--header {
            a {
            position: relative;
                display: block;
                width: 100%;
                aspect-ratio: 16/11;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 3px;
                    overflow: hidden;
                    transition: all .2s ease-in-out;
                }
            }
        }
        .--body {
            padding: 10px 0 0;
            h3 {
                font-size: 1.5rem !important;
                transition: all .2s ease-in-out;
                i { transition: all .2s ease-in-out; }
                &:hover {
                    color: $secondary !important;

                    i {
                        padding-left: 30px;
                    }
                }
            }
        }
    }
}