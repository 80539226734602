@each $type, $Fontsize, $Fontline-height, $Fontweight in $fontSizing {
    #{$type} {
        font-size: $Fontsize;
        line-height: $Fontline-height;
        font-weight: $Fontweight;
    }
    #{$type}.larger { font-size: $Fontsize * 1.5; }
    @media (max-width: $breakpoint-xl) {
        #{$type} { font-size: $Fontsize * .9; }
        #{$type}.larger { font-size: $Fontsize * 1.1; }
    }
    @media (max-width: $breakpoint-md) {
        #{$type} { font-size: $Fontsize * .85; }
        #{$type}.larger { font-size: $Fontsize * .975; }
    }
}

p {
    font-size: 1rem;
    line-height: 1.65;
    font-weight: 400;
}

p.light {
    color: $lightgrey;
}