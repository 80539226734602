::selection { background: $primary; color: #fff; }
::-moz-selection { background: $primary; color: #fff; }
html::-webkit-scrollbar { width: 4px; }
html::-webkit-scrollbar-track { background-color: transparent; }
html::-webkit-scrollbar-thumb { background: $primary; }
ul { padding: 0; }

* {
    box-sizing: border-box;
}

html,body {
    margin: 0;
    padding: 0;
}

ul,ol {
    margin-left: 1rem !important;
    li {
        font-size: 1rem;
        margin-bottom: 15px;
    }
}
.dreamit-section-text {
    ul,ol {
        margin-left: 1rem;
    }
}
a {
    text-decoration: none;
}
.footer-bottom-area {
    background: mix($primary, black, 80%);
}

.choose-us-title {
    h3:hover {
        color: $success;
        cursor: pointer;
    }
}

.__service-box {
    padding: 12px 13px; border-radius: 5px;
    transition: all .2s ease-in-out;
    &:hover {
        background: #f2f2f2;
    }
}

ul.grid {
    width: 100%;
    display: grid; grid-template-columns: repeat(2, 1fr);
    @media (max-width: $breakpoint-md) {
        grid-template-columns: repeat(1, 1fr);
    }
}