.color-gray,.color-grey                     { color: $lightgrey }

.underline                      { text-decoration: underline; }
.no-underline                   { text-decoration: none !important; }

.border-radius                  { border-radius: $border-radius }

.aspect-1                       { aspect-ratio: 1/1 }
.aspect-16-9                    { aspect-ratio: 16/9 }

.center-items                   { display: grid; place-items: center }

// ---------------------------------------
// \ MEDIA-QUERY'S W/ MAX-WIDTH
// ---------------------------------------
@each $name, $size in $grid-breakpoints {
    @media (max-width: #{$size}) {
        .rm-#{$name} { display: none !important; }

        .aspect-t-#{$name}-1      { aspect-ratio: 1/1; }
    }
}

// ---------------------------------------
// \ MEDIA-QUERY'S W/ MIN-WIDTH + 1px
// ---------------------------------------
@each $name, $size in $grid-breakpoints {
    @media (min-width: calc(#{$size} + 1px)) {
        .vh-#{$name}-100 { height: 100vh }

        //aspect-radio
        .aspect-#{$name}-1      { aspect-ratio: 1/1; }
        .aspect-#{$name}-16-9   { aspect-ratio: 16/9; }
    }
}
.ob-fi-co { object-fit: cover; }
.text-light { color: #FDE8CE !important; }