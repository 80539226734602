.btn {
  border-radius: $pill;
  padding: 8px 35px;
  transition: all .2s ease-in-out;
  i {
    transition: all .2s ease-in-out;
  }
  &:hover {
    box-shadow: 0 0 0 8px mix($secondary, transparent, 25%);
    i {
      padding-left: 20px;
    }
  }

  &.main {
    background: $primary;
    color: $white;

    &:hover {
      background: $primary-darker;
      box-shadow: $shadow-softer;
    }
  }

  &.hallow {
    background: transparent;
    color: $primary;
    border: 1px solid $primary;

    &:hover {
      background: $primary;
      color: #fff;
    }
  }

  &.underline {
    &.main {
      color: $primary !important;
      border-color: $primary;
    }
    background: transparent;
    border-radius: 0;
    border: none !important;
    border-bottom: 1px solid !important;
    padding: 3px 20px;
    margin: 0 15px;


    &:hover {
      background: transparent !important;
      box-shadow: none !important;
      transform: scale(1.04);
    }
  }
}