//|--------------------------------------------------------------------------
//| Page Contact
//| Edited: 18-09-2023
section.contact {
    .--credentials {
        background: #eaeaea; border-radius: 15px;
        margin-top: 2rem;
        padding: 45px 45px 35px;  //padding bottom different because of last item padding bottom.
        @media (max-width: $breakpoint-md) {
            padding: 30px 15px 20px;  //padding bottom different because of last item padding bottom.
        }
        @media (min-width: calc($breakpoint-lg + 1px)) {
            margin: -29px -15px 0 0;
            padding: 60px 45px 50px; //padding bottom different because of last item padding bottom.
        }
        .--person {
            img {
                object-fit: cover;
                @media (max-width: $breakpoint-sm) {
                    width: 125px !important; height: 125px !important;
                }
            }
        }

        .--items {
            .--item {
                .__container {
                    flex: 0 0 32px;
                    width: 32px; height: 32px;
                    border-radius: 5px;
                    background: $primary;
                    display: flex; align-items: center; justify-content: center;
                    i { color: #fff; }
                }
                a {
                    text-decoration: none; color: $text;
                    &:hover { color: $primary; }
                }
            }
        }
    }

    .form-group {
        .error-help-block {
            color: $danger;
        }
    }
}