.styled-form {
    .form-group {
        label {
            font-size: 1.1rem;
        }
        input,textarea {
            border-radius: 10px;
            padding: 10px 15px;
            &:read-only {
                background: #f5f5f5;
                cursor: not-allowed;
            }
        }
        textarea { resize: none; height: 250px }

        button {
            padding: 14px 40px;
            background: $primary; color: #fff;
            border-radius: 10px;
            border: none; outline: none;
            transition: all .2s ease-in-out;
            &:hover {
                box-shadow: 0 0 0 5px mix($primary, rgba(0,0,0,0), 40%);
            }
        }
    }
}