section.service-cta {
    padding: 4rem 0;
    .cta--parent {
        background-size: cover;
        background-position: center;
        border-radius: 10px;
        display: flex;
        box-shadow: 0 0 15px rgba(53, 107, 159, 0.1);

        .content {
            padding: 30px 50px;
            @media (max-width: $breakpoint-md) {
                padding: 30px 25px;
                h2 {
                    font-size: 1.75rem !important;
                }
            }
            @media (max-width: $breakpoint-sm) {
                padding: 30px 15px;
            }
        }
        
        .visual {
            flex: 1 0 auto;
            height: 100%;
            aspect-ratio: 1;
            border-radius: 10px;
            background: #dde9ff;
            position: relative;

            img {
                position: absolute; left: 50%; transform: translateX(-50%);
                bottom: 0;
                height: calc(100% + 75px);
                filter: drop-shadow(0 0 15px rgba(53, 107, 159, 0.4));
            }
        }
    }
}