section.image-text {
    @media (max-width: $breakpoint-sm) {
        .row {
            padding: 0 .75rem;
        }
    }
    &:nth-of-type(even) {
        background: #fafafa;
    }
    ul,ol {
        margin-left: 1rem;
    }
    .col-lg-6 > * { margin-top: 0 !important; }
}

section.dreamit-choose-us-area, .dreamit-about-area, section.sector-subhero, section.contact {
    @media (max-width: $breakpoint-sm) {
        .row {
            padding: 0 .75rem;
        }
    }
}