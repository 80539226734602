footer {
    .footer-menu {
        li {
            margin-bottom: 0 !important;
            a.active {
                color: $secondary !important;
            }
        }
        ul.links {
            li a {
                span {
                    display: block !important;
                    max-width: 230px;
                    text-overflow: ellipsis; white-space: nowrap;
                    overflow: hidden;
                }
            }
        }
    }

    .footer-logo {
        padding-right: 25px;
        @media (max-width: $breakpoint-md) {
            padding-right: 15px;
        }
    }

    .btn-secondary {
        transition: all .2s ease-in-out;
        i { transition: all .2s ease-in-out; }
        &:hover {
            i { padding-left: 20px; }
        }
    }

    .copy-right-text {
        a:hover {
            color: $secondary !important;
        }
    }
}