section.certificate_slider {
    padding: 3.5rem 0 !important;
    .row > h3 {
        margin-bottom: 2.25rem;
    }
    .slider__item {
        position: relative;

        *       { transition: all .2s ease-in-out }
        img     {
            filter: grayscale(1);
            width: 100% !important; max-height: 170px;
            object-fit: scale-down;
            aspect-ratio: 1;
        }

        &:hover {
            img { filter: grayscale(0); }
        }
    }
    .owl-dots {
        margin-top: 25px !important;
    }
}