#cookieNotice {
    &.hidden {
        display: none; visibility: hidden;
        opacity: 0;
    }
    position: fixed; z-index: 99999;
    left: 0; bottom: 0;
    margin: 15px;

    @media (max-width: $breakpoint-sm) {
        margin: 0;
        width: 100%;
    }

    .text--container {
        h3 {
            font-size: 1.65rem !important;
        }
    }
    .cookie-notice {
        padding: 30px; background: #fff;
        border-radius: $border-radius; box-shadow: $shadow-soft;

        .cookie-consent__agree {
            background: $primary !important;
            &:hover {
                background: mix($primary, black, 80%) !important;
            }
        }

        @media (max-width: $breakpoint-sm) {
            border-radius: 0;
            width: 100%;
            .content {
                display: flex; align-items: flex-end;
                gap: 20px;
                .text--container {
                    h3 {
                        @media (max-width: 380px) {
                            font-size: 1.4rem !important;
                        }
                        @media (max-width: 340px) {
                            font-size: 1.2rem !important;
                        }
                    }
                    p {
                        width: 100%;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 5; line-clamp: 5; -webkit-box-orient: vertical;
                        @media (max-width: 460px) {
                            -webkit-line-clamp: 4; line-clamp: 4;
                        }
                        @media (max-width: 410px) {
                            -webkit-line-clamp: 3; line-clamp: 3;
                        }
                        @media (max-width: 360px) {
                            -webkit-line-clamp: 2; line-clamp: 2;
                        }
                    }
                    #showCookieModal {
                        display: block !important; margin-bottom: 10px; margin-top: -10px;
                    }
                }
                .buttons--container {
                    display: flex; flex-direction: column; justify-content: flex-end;
                    gap: 10px;
                    button {
                        margin: 0 !important;
                    }
                }
            }
        }
        p {
            width: 450px;
            font-size: .7rem;
            max-width: calc(100vw - 90px);
        }
    }
}

.cookie-consent {
    #cookieModal {
        z-index: 99999;
        .modal-dialog {
            margin: 50% 15px;
        }
        .modal-content {
            background: #fff !important;
            border-radius: $border-radius;
            box-shadow: 0 0 0 1000px rgba(0, 0, 0, .25);
            .modal-body {
                position: relative;

                button {
                    position: absolute;
                    right: 15px; top: 15px;
                    border-radius: 5px !important;
                    background: mix($primary, white, 15%);
                    width: 25px !important; height: 25px !important;
                    display: flex !important; align-items: center; justify-content: center;
                    opacity: 1 !important;
                    i {
                        height: 16px !important;
                        &::before {
                            background-color: $primary !important;
                        }
                    }
                }
            }
        }
    }
}
