section.hero {
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
    @media (min-width: calc($breakpoint-xl + 1px)) {
        background-size: cover;
    }

    h2 {
        color: #fff;
        margin-bottom: 15px !important;
    }

    .slider-button a {
        transition: all .2s ease-in-out;
        i {
            transition: all .2s ease-in-out;
        }
        &:hover {
            box-shadow: 0 0 0 8px mix($secondary, transparent, 25%);
            i {
                padding-left: 20px;
            }
        }
    }
}