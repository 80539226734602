// VARIABLES - MUST BE LOADED FIRST!
@import 'variables';

// DEPENDENCIES
@import '~bootstrap/scss/bootstrap';
@import '~slick-slider/slick/slick';
@import '~slick-slider/slick/slick-theme';

//VENDOR
@import 'vendor/bootstrap-fix';
@import 'owl.carousel/dist/assets/owl.carousel.css';

// GLOBAL
@import 'fonts';
@import 'typography';
@import 'global';
@import 'buttons';
@import 'helper';
@import 'header';
@import 'footer';

// COMPONENTS
@import 'components/services';
@import 'components/certificate-slider';
@import 'components/image-text';
@import 'components/form';
@import 'components/service-cta';
@import 'components/make-an-appointment';
@import 'components/service-area';
@import 'components/cookie-notice';

// PAGE-WISE
@import 'pages/homepage';
@import 'pages/sector';
@import 'pages/contact';