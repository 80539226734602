.--make__an__appointment {
    display: flex; align-items: center;
    position: fixed; right: 0; top: 50%;
    transform: translate(350px, -50%);
    transition: all .33s ease-in-out .25s;
    pointer-events: none;
    z-index: 99999;
    @media (max-width: $breakpoint-sm) { display: none !important; visibility: hidden !important; }
    &.active {
        transform: translate(0, -50%);
    }
    .--outer,.--inner {
        cursor: pointer;
        pointer-events: all !important;
    }
    .--outer {
        padding: 20px;
        display: flex; align-items: center; justify-content: center;
        background: $secondary; border-radius: 5px 0 0 5px;
        transition: all .2s ease-in-out;
        i {
            color: #fff;
            font-size: 2rem;
            animation: tilt-shaking 1.5s ease-in-out;
            animation-delay: 10s;
            &:hover {
                color: #fff;
            }
        }
        &:hover {
            i {
                animation: tilt-shaking 1.5s ease-in-out infinite;
            }
        }
    }
    &.active {
        .--outer {
            background: #eee;
            i { color: $primary; }
        }
    }
    .--inner {
        display: flex; justify-content: center;
        width: 350px;
        background: #eee;
        padding: 30px;

        .btn-primary {
            transition: all .2s ease-in-out;
            i { transition: all .2s ease-in-out; }
            &:hover {
                border-color: $secondary;
                background: $secondary;
                i {
                    padding-left: 20px;
                }
            }
        }
    }

    .__close {
        position: absolute; right: 15px; top: 15px;
        pointer-events: none; cursor: pointer;
    }
}

@keyframes tilt-shaking {
    0% { transform: rotate(0deg); }
    5% { transform: rotate(5deg); }
    10% { transform: rotate(-5deg); }
    15% { transform: rotate(5deg); }
    20% { transform: rotate(-5deg); }
    25% { transform: rotate(5deg); }
    30% { transform: rotate(-5deg); }
    35% { transform: rotate(5deg); }
    40% { transform: rotate(-5deg); }
    45% { transform: rotate(5deg); }
    50% { transform: rotate(-5deg); }
    55% { transform: rotate(5deg); }
    60% { transform: rotate(-5deg); }
    65% { transform: rotate(5deg); }
    70% { transform: rotate(-5deg); }
    75% { transform: rotate(5deg); }
    80% { transform: rotate(-5deg); }
    85% { transform: rotate(5deg); }
    90% { transform: rotate(-5deg); }
    95% { transform: rotate(5deg); }
    100% { transform: rotate(0deg); }
}